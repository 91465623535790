<template>
    <v-container fluid>
        <v-row>
            <v-col
                cols="12"
                lg="12"
                md="12"
                sm="12"
                style="padding: 0px !important;"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h6 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="10"
                                    md="6"
                                    sm="12"
                                >
                                    Job Information
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="2"
                                    md="6"
                                    sm="12"
                                    style="text-align: right;"
                                >
                                    <v-chip
                                        class="d-flex justify-center"
                                        style="width: 200px !important;"
                                        color="var(--color__main)"
                                        outlined
                                        dark
                                    >
                                        {{ serviceProjectResponse.statusDescription }}
                                    </v-chip>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>

                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="8"
                                    md="6"
                                    sm="12"
                                >
                                    <table style="width: 100%; border: none; margin-top: 10px; padding: 4px;">
                                        <tr>
                                            <td class="header">ID</td>
                                            <td class="content"> {{ serviceProjectResponse.idFormatted }}</td>
                                            
                                            <td class="header">Created On</td>
                                            <td class="content"> {{ serviceProjectResponse.dateRegisterFormatted }}</td>
                                        </tr>
                                        <tr>
                                            <td class="header">Sales Person</td>
                                            <td class="content"> {{ serviceRequest.userResponsibleSelected != null && serviceRequest.userResponsibleSelected != undefined ? serviceRequest.userResponsibleSelected.name : '' }}</td>
                                            
                                            <td class="header">Kind of Work</td>
                                            <td class="content"> {{ serviceProjectResponse.kindWorkDescription }}</td>
                                        </tr>
                                        <tr>
                                            <td class="header">Survey</td>
                                            <td class="content"> {{ serviceProjectResponse.surveyDescription }}</td>

                                            <td class="header">Address</td>
                                            <td class="content"> {{ serviceRequest.addressSelected.address }}</td>
                                        </tr>
                                        <tr>
                                            <td class="header">Customer</td>
                                            <td class="content"> {{ serviceRequest.customerSelected.name }}</td>

                                            <td class="header"></td>
                                            <td class="content"></td>
                                        </tr>
                                    </table>
                                </v-col>
                                <v-col 
                                    class="d-flex justify-center"
                                    cols="12"
                                    lg="4"
                                    md="6"
                                    sm="12"
                                >
                                    
                                    <v-chip
                                        class="d-flex justify-center ma-2"
                                        :color="haveTechSpecItems == true ? 'var(--color__main) !important' : 'var(--color__red) !important'"
                                        label
                                        text-color="white"
                                    >
                                        Tech Spec
                                        <v-icon
                                            v-if="haveTechSpecItems == true"
                                            right
                                        >
                                            mdi-check
                                        </v-icon>
                                        <v-icon
                                            v-else
                                            right
                                        >
                                            mdi-close
                                        </v-icon>
                                    </v-chip>

                                    <v-chip
                                        class="d-flex justify-center ma-2"
                                        :color="haveDraftingItems == true ? 'var(--color__main) !important' : 'var(--color__red) !important'"
                                        label
                                        text-color="white"
                                    >
                                        Drafting
                                        <v-icon
                                            v-if="haveDraftingItems == true"
                                            right
                                        >
                                            mdi-check
                                        </v-icon>
                                        <v-icon
                                            v-else
                                            right
                                        >
                                            mdi-close
                                        </v-icon>
                                    </v-chip>
                                    
                                    <v-chip
                                        class="d-flex justify-center ma-2"
                                        :color="havePermittingItems == true ? 'var(--color__main) !important' : 'var(--color__red) !important'"
                                        label
                                        text-color="white"
                                    >
                                        Permitting
                                        <v-icon
                                            v-if="havePermittingItems == true"
                                            right
                                        >
                                            mdi-check
                                        </v-icon>
                                        <v-icon
                                            v-else
                                            right
                                        >
                                            mdi-close
                                        </v-icon>
                                    </v-chip>
                                    
                                    <v-chip
                                        class="d-flex justify-center ma-2"
                                        :color="haveTechSpecItems == true ? 'var(--color__main) !important' : 'var(--color__red) !important'"
                                        label
                                        text-color="white"
                                    >
                                        Pergolas
                                        <v-icon
                                            v-if="haveTechSpecItems == true"
                                            right
                                        >
                                            mdi-check
                                        </v-icon>
                                        <v-icon
                                            v-else
                                            right
                                        >
                                            mdi-close
                                        </v-icon>
                                    </v-chip>
                                    
                                    <v-chip
                                        class="d-flex justify-center ma-2"
                                        :color="haveTechSpecItems == true ? 'var(--color__main) !important' : 'var(--color__red) !important'"
                                        label
                                        text-color="white"
                                    >
                                        BBQ
                                        <v-icon
                                            v-if="haveTechSpecItems == true"
                                            right
                                        >
                                            mdi-check
                                        </v-icon>
                                        <v-icon
                                            v-else
                                            right
                                        >
                                            mdi-close
                                        </v-icon>
                                    </v-chip>
                                </v-col>
                            </v-row> 
                            <v-row>
                                <v-col 
                                    cols="10"
                                    lg="12"
                                    md="10"
                                    sm="10"
                                >
                                    <v-combobox
                                        v-model="projectSelected"
                                        :items="listProjects"
                                        item-text="nameWithVersion"
                                        label="Project"
                                        item-value="id"
                                        outlined
                                        @change="updateProject()"
                                        dense
                                    ></v-combobox>
                                </v-col>
                            </v-row> 
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
                lg="6"
                md="12"
                sm="12"
                style="padding: 0px !important;"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h6 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Drafting
                        </v-card-title>
                        <v-card-text style="margin-top: 20px;">
                                
                            <v-simple-table
                                fixed-header
                            >
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Type Document
                                            </th>
                                            <th class="text-left">
                                                File Name
                                            </th>
                                            <th class="text-left">
                                                Created On
                                            </th>
                                            <th class="text-left">
                                                Created By
                                            </th>
                                            <th class="text-left"></th>
                                        </tr>
                                    </thead>
                                    <tbody
                                    >
                                        <tr
                                            v-for="(item, index) in listDraftingDocuments" :key="index"
                                        >
                                            <td style="font-weight: bold">{{ item.title }}</td>
                                            <td>{{ item.fileName }}</td>
                                            <td>{{ item.createdOn }}</td>
                                            <td>{{ item.userName }}</td>
                                            <td>
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-chip 
                                                            link 
                                                            color="var(--color__main)"
                                                            style="color: white; width: inherit !important; height: 30px !important;"
                                                            v-on="on"
                                                            small 
                                                            @click="downloadFile(URL_ATTACHMENT, item.id, item.fileType, item.fileName)"
                                                        >
                                                            Download
                                                        </v-chip>
                                                    </template>
                                                    <span>Click to Download [{{ item.fileName }}]</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>
            <v-col
                cols="12"
                lg="6"
                md="12"
                sm="12"
                style="padding: 0px !important;"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h6 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Permitting
                        </v-card-title>
                        <v-card-text style="margin-top: 20px;">
                                
                            <v-simple-table
                                fixed-header
                            >
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                Title
                                            </th>
                                            <th class="text-left">
                                                Status
                                            </th>
                                            <th class="text-left">
                                                Sent to the city?
                                            </th>
                                            <th class="text-left">
                                                File
                                            </th>
                                            <th class="text-left">
                                                NOC File
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody
                                    >
                                        <tr
                                            v-for="(item, index) in listPermitDocuments" :key="index"
                                        >
                                            <td style="font-weight: bold">{{ item.permitTitle }}</td>
                                            <td>{{ item.statusSelected != null ? item.statusSelected.description : '' }}</td>
                                            <td>{{ item.sentCity == 1 ? 'Yes' : 'No' }}</td>
                                            <td>
                                                <v-tooltip 
                                                    v-if="getFile(item.id, typeDocumentOnGoingFile).idFile != 0"
                                                    top
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-chip
                                                            v-if="getFile(item.id, typeDocumentOnGoingFile).idFile != 0"
                                                            link 
                                                            color="var(--color__main)"
                                                            style="color: white; width: inherit !important; height: 30px !important;"
                                                            v-on="on"
                                                            small 
                                                            @click="downloadFile(URL_ATTACHMENT, getFile(item.id, typeDocumentOnGoingFile).idFile, getFile(item.id, typeDocumentOnGoingFile).fileType, getFile(item.id, typeDocumentOnGoingFile).fileName)"
                                                        >
                                                            {{ getFile(item.id, typeDocumentOnGoingFile).fileName }}
                                                        </v-chip>
                                                    </template>
                                                    <span>Click to Download</span>
                                                </v-tooltip>
                                            </td>
                                            <td>
                                                <v-tooltip
                                                    v-if="getFile(item.id, typeDocumentNocFile).idFile != 0"
                                                    top
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-chip
                                                            v-if="getFile(item.id, typeDocumentNocFile).idFile != 0"
                                                            link 
                                                            color="var(--color__main)"
                                                            style="color: white; width: inherit !important; height: 30px !important;"
                                                            v-on="on"
                                                            small 
                                                            @click="downloadFile(URL_ATTACHMENT, getFile(item.id, typeDocumentNocFile).idFile, getFile(item.id, typeDocumentNocFile).fileType, getFile(item.id, typeDocumentNocFile).fileName)"
                                                        >
                                                            {{ getFile(item.id, typeDocumentNocFile).fileName }}
                                                        </v-chip>
                                                    </template>
                                                    <span>Click to Download</span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
                lg="12"
                md="12"
                sm="12"
                style="padding: 0px !important;"
            >
                <v-expansion-panels popout>

                    <!-- TECHNICAL SPECIFICATION -->
                    <v-col
                        cols="12"
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <span class="text-h6 lighten-2">
                                            Technical Specification
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                            
                                <v-expansion-panels popout>

                                    <!-- DETAILS -->
                                    <v-col
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                    >
                                                        <v-icon color="primary" left>
                                                            mdi-information-variant
                                                        </v-icon>
                                                        Details
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row>
                                                    <v-col
                                                        v-if="surveyFile && surveyFile.id != 0"
                                                        cols="12"
                                                        lg="3"
                                                        md="6"
                                                        sm="12"
                                                    >
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-card
                                                                    class="cardDownload"
                                                                    v-on="on"
                                                                    @click="downloadFile(URL_ATTACHMENT, surveyFile.id, surveyFile.fileType, surveyFile.fileName)"
                                                                >
                                                                    <v-card-title class="cardTitleDownload">
                                                                        <label>Survey (PDF)</label>
                                                                    </v-card-title>

                                                                    <v-card-subtitle>
                                                                        <span>{{ surveyFile.createdOn }} by {{ surveyFile.userName }}</span>
                                                                    </v-card-subtitle>

                                                                    <v-card-text>
                                                                        <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                                                        <span class="imageFileName">{{ surveyFile.fileName }}</span>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </template>
                                                            <span>Click to Download</span>
                                                        </v-tooltip>
                                                        
                                                    </v-col>

                                                    <v-col
                                                        v-if="architecturalSitePlanFile && architecturalSitePlanFile.id != 0"
                                                        cols="12"
                                                        lg="3"
                                                        md="6"
                                                        sm="12"
                                                    >
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-card
                                                                    class="cardDownload"
                                                                    v-on="on"
                                                                    @click="downloadFile(URL_ATTACHMENT, architecturalSitePlanFile.id, architecturalSitePlanFile.fileType, architecturalSitePlanFile.fileName)"
                                                                >
                                                                    <v-card-title class="cardTitleDownload">
                                                                        <label>Architectural Site Plan  (PDF, CAD, DWG)</label>
                                                                    </v-card-title>

                                                                    <v-card-subtitle>
                                                                        <span>{{ architecturalSitePlanFile.createdOn }} by {{ architecturalSitePlanFile.userName }}</span>
                                                                    </v-card-subtitle>

                                                                    <v-card-text>

                                                                        <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                                                        <span class="imageFileName">{{ architecturalSitePlanFile.fileName }}</span>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </template>
                                                            <span>Click to Download</span>
                                                        </v-tooltip>
                                                        
                                                    </v-col>

                                                    <v-col
                                                        v-if="poolStudioDXFFile && poolStudioDXFFile.id != 0"
                                                        cols="12"
                                                        lg="3"
                                                        md="6"
                                                        sm="12"
                                                    >
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-card
                                                                    class="cardDownload"
                                                                    v-on="on"
                                                                    @click="downloadFile(URL_ATTACHMENT, poolStudioDXFFile.id, poolStudioDXFFile.fileType, poolStudioDXFFile.fileName)"
                                                                >
                                                                    <v-card-title class="cardTitleDownload">
                                                                        <label>Pool Studio File (DXF)</label>
                                                                    </v-card-title>

                                                                    <v-card-subtitle>
                                                                        <span>{{ poolStudioDXFFile.createdOn }} by {{ poolStudioDXFFile.userName }}</span>
                                                                    </v-card-subtitle>

                                                                    <v-card-text>

                                                                        <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                                                        <span class="imageFileName">{{ poolStudioDXFFile.fileName }}</span>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </template>
                                                            <span>Click to Download</span>
                                                        </v-tooltip>
                                                        
                                                    </v-col>

                                                    <v-col
                                                        v-if="poolStudioPDFFile && poolStudioPDFFile.id != 0"
                                                        cols="12"
                                                        lg="3"
                                                        md="6"
                                                        sm="12"
                                                    >
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-card
                                                                    class="cardDownload"
                                                                    v-on="on"
                                                                    @click="downloadFile(URL_ATTACHMENT, poolStudioPDFFile.id, poolStudioPDFFile.fileType, poolStudioPDFFile.fileName)"
                                                                >
                                                                    <v-card-title class="cardTitleDownload">
                                                                        <label>Pool Studio File (PDF)</label>
                                                                    </v-card-title>

                                                                    <v-card-subtitle>
                                                                        <span>{{ poolStudioPDFFile.createdOn }} by {{ poolStudioPDFFile.userName }}</span>
                                                                    </v-card-subtitle>

                                                                    <v-card-text>

                                                                        <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                                                        <span class="imageFileName">{{ poolStudioPDFFile.fileName }}</span>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </template>
                                                            <span>Click to Download</span>
                                                        </v-tooltip>
                                                        
                                                    </v-col>

                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-col>
                                    
                                    <!-- EQUIPMENT LOCATION -->
                                    <v-col
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                    >
                                                        <v-icon color="primary" left>
                                                            mdi-arrow-split-vertical
                                                        </v-icon>
                                                        Equipment Location
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row>
                                                    <v-col
                                                        v-for="(itemPhoto, index) in dimensionedEquipmentLocationPhotos"
                                                        :key="index"
                                                        cols="12"
                                                        lg="2"
                                                        md="6"
                                                        sm="12"
                                                    >
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-card
                                                                    class="cardDownload"
                                                                    v-on="on"
                                                                    @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                                                >
                                                                    <v-card-title class="cardTitlePhotosDownload">
                                                                        <label>{{ itemPhoto.fileName }}</label>
                                                                    </v-card-title>

                                                                    <v-card-text>
                                                                        <v-img
                                                                            :src="itemPhoto.src"
                                                                            :lazy-src="itemPhoto.src"
                                                                            aspect-ratio="1"
                                                                            max-height="200"
                                                                            max-width="250"
                                                                            class="cardImagePhotosDownload"
                                                                        >
                                                                            <template v-slot:placeholder>
                                                                            <v-row
                                                                                class="fill-height ma-0"
                                                                                align="center"
                                                                                justify="center"
                                                                            >
                                                                                <v-progress-circular
                                                                                    indeterminate
                                                                                    color="grey lighten-5"
                                                                                ></v-progress-circular>
                                                                            </v-row>
                                                                            </template>
                                                                        </v-img>
                                                                        <span>{{ itemPhoto.createdOn }} by {{ itemPhoto.userName }}</span>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </template>
                                                            <span>Click to Download</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                    >
                                                        <label>Notes</label>
                                                        <v-textarea
                                                            v-model="serviceTechSpecRequest.dimensionedEquipmentLocationNotes"
                                                            rows="4"
                                                            row-height="30"
                                                            auto-grow
                                                            outlined
                                                            disabled
                                                        >
                                                        </v-textarea>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-col>

                                    <!-- POOL BARRIER -->
                                    <v-col
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                    >
                                                        <v-icon color="primary" left style="margin-top: -6px;">
                                                            mdi-fence
                                                        </v-icon>
                                                        Pool Barrier
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                        lg="12"
                                                        md="12"
                                                        sm="12"
                                                    >
                                                        <label>Fence Type</label>
                                                        <v-combobox
                                                            v-model="fenceTypeSelected"
                                                            :items="listFenceType"
                                                            item-text="description"
                                                            item-value="id"
                                                            chips
                                                            multiple
                                                            clearable
                                                            outlined
                                                            disabled
                                                            dense
                                                        ></v-combobox>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-col>

                                    <!-- FENCE LOCATION -->
                                    <v-col
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                    >
                                                        <v-icon color="primary" left>
                                                            mdi-fence-electric
                                                        </v-icon>
                                                        Fence Locations
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row>
                                                    <v-col
                                                        v-for="(itemPhoto, index) in fenceLocationPhotos"
                                                        :key="index"
                                                        cols="12"
                                                        lg="2"
                                                        md="6"
                                                        sm="12"
                                                    >
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-card
                                                                    class="cardDownload"
                                                                    v-on="on"
                                                                    @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                                                >
                                                                    <v-card-title class="cardTitlePhotosDownload">
                                                                        <label>{{ itemPhoto.fileName }}</label>
                                                                    </v-card-title>

                                                                    <v-card-text>
                                                                        <v-img
                                                                            :src="itemPhoto.src"
                                                                            :lazy-src="itemPhoto.src"
                                                                            aspect-ratio="1"
                                                                            max-height="200"
                                                                            max-width="250"
                                                                            class="cardImagePhotosDownload"
                                                                        >
                                                                            <template v-slot:placeholder>
                                                                            <v-row
                                                                                class="fill-height ma-0"
                                                                                align="center"
                                                                                justify="center"
                                                                            >
                                                                                <v-progress-circular
                                                                                    indeterminate
                                                                                    color="grey lighten-5"
                                                                                ></v-progress-circular>
                                                                            </v-row>
                                                                            </template>
                                                                        </v-img>
                                                                        <span>{{ itemPhoto.createdOn }} by {{ itemPhoto.userName }}</span>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </template>
                                                            <span>Click to Download</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                    >
                                                        <label>Notes</label>
                                                        <v-textarea
                                                            v-model="serviceTechSpecRequest.fenceLocationNotes"
                                                            rows="4"
                                                            row-height="30"
                                                            auto-grow
                                                            outlined
                                                            disabled
                                                        >
                                                        </v-textarea>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-col>

                                    <!-- DEPTH AND DEEPEST POINT DESIRED LOCATION -->
                                    <v-col
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                    >
                                                        <v-icon color="primary" left>
                                                            mdi-graph-outline
                                                        </v-icon>
                                                        Depth and Deepest Point Desired Location
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row>
                                                    <v-col
                                                        v-for="(itemPhoto, index) in depthDeepestPointLocationPhotos"
                                                        :key="index"
                                                        cols="12"
                                                        lg="2"
                                                        md="6"
                                                        sm="12"
                                                    >
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-card
                                                                    class="cardDownload"
                                                                    v-on="on"
                                                                    @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                                                >
                                                                    <v-card-title class="cardTitlePhotosDownload">
                                                                        <label>{{ itemPhoto.fileName }}</label>
                                                                    </v-card-title>

                                                                    <v-card-text>
                                                                        <v-img
                                                                            :src="itemPhoto.src"
                                                                            :lazy-src="itemPhoto.src"
                                                                            aspect-ratio="1"
                                                                            max-height="200"
                                                                            max-width="250"
                                                                            class="cardImagePhotosDownload"
                                                                        >
                                                                            <template v-slot:placeholder>
                                                                            <v-row
                                                                                class="fill-height ma-0"
                                                                                align="center"
                                                                                justify="center"
                                                                            >
                                                                                <v-progress-circular
                                                                                    indeterminate
                                                                                    color="grey lighten-5"
                                                                                ></v-progress-circular>
                                                                            </v-row>
                                                                            </template>
                                                                        </v-img>
                                                                        <span>{{ itemPhoto.createdOn }} by {{ itemPhoto.userName }}</span>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </template>
                                                            <span>Click to Download</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                    >
                                                        <label>Notes</label>
                                                        <v-textarea
                                                            v-model="serviceTechSpecRequest.depthDeepestPointLocationNotes"
                                                            rows="4"
                                                            row-height="30"
                                                            auto-grow
                                                            outlined
                                                            disabled
                                                        >
                                                        </v-textarea>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-col>

                                    <!-- LIGHT LOCATION -->
                                    <v-col
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                    >
                                                        <v-icon color="primary" left>
                                                            mdi-lightbulb-question-outline
                                                        </v-icon>
                                                        Light Locations
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row>
                                                    <v-col
                                                        v-for="(itemPhoto, index) in lightLocationsPhotos"
                                                        :key="index"
                                                        cols="12"
                                                        lg="2"
                                                        md="6"
                                                        sm="12"
                                                    >
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-card
                                                                    class="cardDownload"
                                                                    v-on="on"
                                                                    @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                                                >
                                                                    <v-card-title class="cardTitlePhotosDownload">
                                                                        <label>{{ itemPhoto.fileName }}</label>
                                                                    </v-card-title>

                                                                    <v-card-text>
                                                                        <v-img
                                                                            :src="itemPhoto.src"
                                                                            :lazy-src="itemPhoto.src"
                                                                            aspect-ratio="1"
                                                                            max-height="200"
                                                                            max-width="250"
                                                                            class="cardImagePhotosDownload"
                                                                        >
                                                                            <template v-slot:placeholder>
                                                                            <v-row
                                                                                class="fill-height ma-0"
                                                                                align="center"
                                                                                justify="center"
                                                                            >
                                                                                <v-progress-circular
                                                                                    indeterminate
                                                                                    color="grey lighten-5"
                                                                                ></v-progress-circular>
                                                                            </v-row>
                                                                            </template>
                                                                        </v-img>
                                                                        <span>{{ itemPhoto.createdOn }} by {{ itemPhoto.userName }}</span>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </template>
                                                            <span>Click to Download</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                    >
                                                        <label>Notes</label>
                                                        <v-textarea
                                                            v-model="serviceTechSpecRequest.lightLocationsNotes"
                                                            rows="4"
                                                            row-height="30"
                                                            auto-grow
                                                            outlined
                                                            disabled
                                                        >
                                                        </v-textarea>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-col>

                                    <!-- SOIL TEST -->
                                    <v-col
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                    >
                                                        <v-icon color="primary" left>
                                                            mdi-floor-plan
                                                        </v-icon>
                                                        Soil Test
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row>
                                                    <v-col
                                                        v-if="soilTestFile && soilTestFile.id != 0"
                                                        cols="12"
                                                        lg="3"
                                                        md="6"
                                                        sm="12"
                                                    >
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-card
                                                                    class="cardDownload"
                                                                    v-on="on"
                                                                    @click="downloadFile(URL_ATTACHMENT, soilTestFile.id, soilTestFile.fileType, soilTestFile.fileName)"
                                                                >
                                                                    <v-card-title class="cardTitleDownload">
                                                                        <label>Soil Test (PDF)</label>
                                                                    </v-card-title>

                                                                    <v-card-subtitle>
                                                                        <span>{{ soilTestFile.createdOn }} by {{ soilTestFile.userName }}</span>
                                                                    </v-card-subtitle>

                                                                    <v-card-text>
                                                                        <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                                                        <span class="imageFileName">{{ soilTestFile.fileName }}</span>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </template>
                                                            <span>Click to Download</span>
                                                        </v-tooltip>
                                                        
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-col>

                                    <!-- PERGOLAS -->
                                    <v-col
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                    >
                                                        <v-icon color="primary" left>
                                                            mdi-home-edit-outline
                                                        </v-icon>
                                                        Pergolas
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row>
                                                    <v-col
                                                        v-for="(itemPhoto, index) in pergolasPhotos"
                                                        :key="index"
                                                        cols="12"
                                                        lg="2"
                                                        md="6"
                                                        sm="12"
                                                    >
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-card
                                                                    class="cardDownload"
                                                                    v-on="on"
                                                                    @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                                                >
                                                                    <v-card-title class="cardTitlePhotosDownload">
                                                                        <label>{{ itemPhoto.fileName }}</label>
                                                                    </v-card-title>

                                                                    <v-card-text>
                                                                        <v-img
                                                                            :src="itemPhoto.src"
                                                                            :lazy-src="itemPhoto.src"
                                                                            aspect-ratio="1"
                                                                            max-height="200"
                                                                            max-width="250"
                                                                            class="cardImagePhotosDownload"
                                                                        >
                                                                            <template v-slot:placeholder>
                                                                            <v-row
                                                                                class="fill-height ma-0"
                                                                                align="center"
                                                                                justify="center"
                                                                            >
                                                                                <v-progress-circular
                                                                                    indeterminate
                                                                                    color="grey lighten-5"
                                                                                ></v-progress-circular>
                                                                            </v-row>
                                                                            </template>
                                                                        </v-img>
                                                                        <span>{{ itemPhoto.createdOn }} by {{ itemPhoto.userName }}</span>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </template>
                                                            <span>Click to Download</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                    >
                                                        <label>Notes</label>
                                                        <v-textarea
                                                            v-model="serviceTechSpecRequest.pergolasNotes"
                                                            rows="4"
                                                            row-height="30"
                                                            auto-grow
                                                            outlined
                                                            disabled
                                                        >
                                                        </v-textarea>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-col>

                                    <!-- WATER FEATURES -->
                                    <v-col
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                    >
                                                        <v-icon color="primary" left>
                                                            mdi-pool
                                                        </v-icon>
                                                        Water Features
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row>
                                                    <v-col
                                                        v-for="(itemPhoto, index) in waterFeaturesPhotos"
                                                        :key="index"
                                                        cols="12"
                                                        lg="2"
                                                        md="6"
                                                        sm="12"
                                                    >
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-card
                                                                    class="cardDownload"
                                                                    v-on="on"
                                                                    @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                                                >
                                                                    <v-card-title class="cardTitlePhotosDownload">
                                                                        <label>{{ itemPhoto.fileName }}</label>
                                                                    </v-card-title>

                                                                    <v-card-text>
                                                                        <v-img
                                                                            :src="itemPhoto.src"
                                                                            :lazy-src="itemPhoto.src"
                                                                            aspect-ratio="1"
                                                                            max-height="200"
                                                                            max-width="250"
                                                                            class="cardImagePhotosDownload"
                                                                        >
                                                                            <template v-slot:placeholder>
                                                                            <v-row
                                                                                class="fill-height ma-0"
                                                                                align="center"
                                                                                justify="center"
                                                                            >
                                                                                <v-progress-circular
                                                                                    indeterminate
                                                                                    color="grey lighten-5"
                                                                                ></v-progress-circular>
                                                                            </v-row>
                                                                            </template>
                                                                        </v-img>
                                                                        <span>{{ itemPhoto.createdOn }} by {{ itemPhoto.userName }}</span>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </template>
                                                            <span>Click to Download</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                    >
                                                        <label>Notes</label>
                                                        <v-textarea
                                                            v-model="serviceTechSpecRequest.waterFeaturesNotes"
                                                            rows="4"
                                                            row-height="30"
                                                            auto-grow
                                                            outlined
                                                            disabled
                                                        >
                                                        </v-textarea>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-col>
                                    
                                    <!-- BBQs -->
                                    <v-col
                                        cols="12"
                                        lg="12"
                                        md="12"
                                        sm="12"
                                    >
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                    >
                                                        <v-icon color="primary" left>
                                                            mdi-grill
                                                        </v-icon>
                                                        BBQ´s
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-row>
                                                    <v-col
                                                        v-for="(itemPhoto, index) in bbqPhotos"
                                                        :key="index"
                                                        cols="12"
                                                        lg="2"
                                                        md="6"
                                                        sm="12"
                                                    >
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on }">
                                                                <v-card
                                                                    class="cardDownload"
                                                                    v-on="on"
                                                                    @click="downloadFile(URL_ATTACHMENT, itemPhoto.id, itemPhoto.fileType, itemPhoto.fileName)"
                                                                >
                                                                    <v-card-title class="cardTitlePhotosDownload">
                                                                        <label>{{ itemPhoto.fileName }}</label>
                                                                    </v-card-title>

                                                                    <v-card-text>
                                                                        <v-img
                                                                            :src="itemPhoto.src"
                                                                            :lazy-src="itemPhoto.src"
                                                                            aspect-ratio="1"
                                                                            max-height="200"
                                                                            max-width="250"
                                                                            class="cardImagePhotosDownload"
                                                                        >
                                                                            <template v-slot:placeholder>
                                                                            <v-row
                                                                                class="fill-height ma-0"
                                                                                align="center"
                                                                                justify="center"
                                                                            >
                                                                                <v-progress-circular
                                                                                    indeterminate
                                                                                    color="grey lighten-5"
                                                                                ></v-progress-circular>
                                                                            </v-row>
                                                                            </template>
                                                                        </v-img>
                                                                        <span>{{ itemPhoto.createdOn }} by {{ itemPhoto.userName }}</span>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </template>
                                                            <span>Click to Download</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col
                                                        cols="12"
                                                    >
                                                        <label>Notes</label>
                                                        <v-textarea
                                                            v-model="serviceTechSpecRequest.bbqNotes"
                                                            rows="4"
                                                            row-height="30"
                                                            auto-grow
                                                            outlined
                                                            disabled
                                                        >
                                                        </v-textarea>
                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-col>
                                </v-expansion-panels>

                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-col>
                </v-expansion-panels>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
                lg="12"
                md="12"
                sm="12"
                style="padding: 0px !important;"
            >
                <v-expansion-panels popout>

                    <!-- TECHNICAL SPECIFICATION -->
                    <v-col
                        cols="12"
                        lg="12"
                        md="12"
                        sm="12"
                    >
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <v-row>
                                    <v-col
                                        cols="12"
                                    >
                                        <span class="text-h6 lighten-2">
                                            History
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                    
                                <v-data-table
                                    :headers="headersHistory"
                                    :items="listHistory"
                                    :items-per-page="10"
                                    no-data-text="No history registered"
                                >
                                </v-data-table>

                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-col>
                </v-expansion-panels>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import { formaterDecimalBR } from '@/utilities/Utils';
    import { TYPE_DOCUMENT, TYPE_ATTACHMENT, URL_ATTACHMENT } from "@/utilities/Enums";
    import Vue from "vue";

    export default ({

        mixins: [Helpers],

        props: {
            guid: {
                type: String,
                default: ""
            }
        },

        data: () => ({

            URL_ATTACHMENT,

			serviceProjectResponse: {
                id: 0,
                userNameRegister: ""
            },
			
            serviceRequest: {
                id: 0,
                userNameRegister: "",
                dateRegisterFormatted: "",
                customerSelected: {
                    name: ""
                },
                addressSelected: {
                    address: ""
                },
                userResponsibleSelected: {
                    name: ""
                },
            },

            projectSelected: null,
            listProjects: [],

            serviceTechSpecRequest: {
                id: 0,
                idService: 0,
                surveyTypeSelected: null,
                dimensionedEquipmentLocation: 0,
                dimensionedEquipmentLocationNotes: "",
                fenceType: "",
                fenceLocation: 0,
                fenceLocationNotes: "",
                depthDeepestPointLocation: 0,
                depthDeepestPointLocationNotes: "",
                lightLocations: 0,
                lightLocationsNotes: "",
                soilTest: 0,
                pergolas: 0,
                pergolasNotes: "",
                waterFeatures: 0,
                waterFeaturesNotes: "",
                bbq: 0,
                bbqNotes: ""
            },

            titles: {
                serviceInformation: "Job information",
                scopeOfWork: "Scope of Work",
                details: "Details",
                dimensionedEquipmentLocation: "Equipment Location",
                fenceEnclosureInformation: "Pool Barrier",
                fenceLocation: "Fence Location",
                depthDeepestPointLocation: "Depth Deepest Point Location",
                lightLocations: "Light Locations",
                soilTest: "Soil Test",
                pergolas: "Pergolas",
                waterFeatures: "Water Features",
                bbq: "BBQ´s",
            },

            itemTitle: "",
            itemReasonDescription: "",
            showDialogItemNote: false,

            listProductSelecteds: [],

            idServiceItem: 0,

            grandTotal: "$ 0.00",

            surveyFile: {
                id: 0,
                fileName: "",
                createdOn: "",
                userName: "",
            },

            poolStudioDXFFile: {
                id: 0,
                fileName: "",
                createdOn: "",
                userName: "",
            },

            poolStudioPDFFile: {
                id: 0,
                fileName: "",
                createdOn: "",
                userName: "",
            },

            architecturalSitePlanFile: {
                id: 0,
                fileName: "",
                createdOn: "",
                userName: "",
            },

            dimensionedEquipmentLocationPhotos: [],
            fenceLocationPhotos: [],
            depthDeepestPointLocationPhotos: [],
            lightLocationsPhotos: [],

            soilTestFile: {
                id: 0,
                fileName: "",
                createdOn: "",
                userName: "",
            },

            pergolasPhotos: [],
            waterFeaturesPhotos: [],
            bbqPhotos: [],

            fenceTypeSelected: [],

            listSurveyType: [
                { id: 1, description: 'Temp' },
                { id: 2, description: 'Final' },
                { id: 3, description: 'More Than 5 Year' }
            ],

            listFenceType: [
                { id: 1, description: 'Pool Cover' },
                { id: 2, description: 'Baby Fence' },
                { id: 3, description: 'Alarm' },
                { id: 4, description: 'Combination' },
                { id: 5, description: 'Screen Enclosure' },
                { id: 6, description: 'AI Cameras' },
                { id: 7, description: 'Perimeter Fence' },
                { id: 8, description: 'Floating Alarm' },
                { id: 9, description: 'Cooping Alarm' },
            ],

            totalProject: "$ 0.00",

            listClassification: [
                { id: '1',  description: '1 - Highest Prioirty' },
                { id: '2',  description: '2 - High Priority' },
                { id: '3',  description: '3 - Medium Priority' },
                { id: '4',  description: '4 - Low Priority' }
            ],

            listDraftingDocuments: [],
            listPermitDocuments: [],
            listPermitFiles: [],
            typeDocumentOnGoingFile: TYPE_DOCUMENT.PERMITTING_ONGOING_FILE.value,
            typeDocumentNocFile: TYPE_DOCUMENT.PERMITTING_NOC.value,
            listTechSpecDocuments: [],

            //HISTORY
            listHistory: [],
            headersHistory: [
                { text: "Date", value: "dateRegisterFormatted", sortable: true },
                { text: "Department", value: "profileName", sortable: true },
                { text: "Subject", value: "description", sortable: true },
                { text: "Username", value: "userName", sortable: true },
            ],
        }),

        computed: {
            haveDraftingItems() {
                return this.listDraftingDocuments != null && this.listDraftingDocuments != undefined && this.listDraftingDocuments.length > 0
            },

            havePermittingItems() {
                return this.listPermitDocuments != null && this.listPermitDocuments != undefined && this.listPermitDocuments.length > 0
            },

            haveTechSpecItems() {
                return this.serviceTechSpecRequest != null && this.serviceTechSpecRequest != undefined && this.serviceTechSpecRequest.id > 0
            }
        },

        methods: {
            
            async updateProject() {
                if (this.projectSelected != null && this.projectSelected != undefined) {
                    await this.getRegister(this.projectSelected.id);
                }
            },
            
            async getRegister(idProject) {

                if (this.guid != undefined && this.guid != null && this.guid != "") {

                    let response = await this.$store.dispatch("serviceModule/GetServiceProjectById", idProject);
                    this.serviceProjectResponse = response.result;

                    response = await this.$store.dispatch("serviceModule/GetById", this.serviceProjectResponse.idService);
                    this.serviceRequest = response.result;

                    this.serviceProjectResponse.classificationDescription = "Not classified yet";
                    if (this.serviceProjectResponse.classification != null && this.serviceProjectResponse.classification != undefined) {
                        let itemClassification = this.listClassification.filter(cla => cla.id == this.serviceProjectResponse.classification)[0];
                        this.serviceProjectResponse.classificationDescription = `${itemClassification.description}`;
                    }

                    this.listProjects = await this.$store.dispatch("serviceModule/ListProject", this.serviceRequest.id);
                    let itemProject = this.listProjects.filter(prj => prj.id == this.serviceProjectResponse.id)[0];

                    this.projectSelected = itemProject;

                    this.totalProject = `$ ${formaterDecimalBR(itemProject.totalProject)}`;

                    this.getDraftingDocuments();

                    this.getPermittingDocuments();

                    this.getTechSpec();

                    this.getHistory();
                }
            },

            async getDraftingDocuments() {
                    
                let responseDraftingUploadFiles = await this.$store.dispatch("serviceModule/GetDraftingUploadFilesById", this.serviceProjectResponse.id);

                if (responseDraftingUploadFiles.result.id != 0) {
                    let serviceDraftingUploadFilesRequest = responseDraftingUploadFiles.result;

                    this.listDraftingDocuments = [];
                    
                    let listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.DRAFTING.value, idKey: serviceDraftingUploadFilesRequest.id });

                    let poolPlanListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DRAFTING_POOL_PLAN.value);
                    
                    if (poolPlanListFiles != null && poolPlanListFiles != undefined) {

                        poolPlanListFiles.forEach(itemFile => {

                            this.listDraftingDocuments.push({
                                title: 'Pool Plan',
                                id: itemFile.id,
                                fileName: itemFile.fileName,
                                fileType: itemFile.fileType,
                                createdOn: itemFile.dateRegisterFormatted,
                                userName: itemFile.userNameRegister
                            });
                        });
                    }

                    let designListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DRAFTING_DESIGN_SPECIFICATION.value);
                    if (designListFiles != null && designListFiles != undefined) {

                        designListFiles.forEach(itemFile => {

                            this.listDraftingDocuments.push({
                                title: 'Design Files',
                                id: itemFile.id,
                                fileName: itemFile.fileName,
                                fileType: itemFile.fileType,
                                createdOn: itemFile.dateRegisterFormatted,
                                userName: itemFile.userNameRegister
                            });
                        });
                    }

                    let bbqListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DRAFTING_BARBECUE.value);
                    if (bbqListFiles != null && bbqListFiles != undefined) {

                        bbqListFiles.forEach(itemFile => {

                            this.listDraftingDocuments.push({
                                title: 'Barbecue',
                                id: itemFile.id,
                                fileName: itemFile.fileName,
                                fileType: itemFile.fileType,
                                createdOn: itemFile.dateRegisterFormatted,
                                userName: itemFile.userNameRegister
                            });
                        });
                    }

                    let pergolasListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DRAFTING_PERGOLAS.value);
                    if (pergolasListFiles != null && pergolasListFiles != undefined) {

                        pergolasListFiles.forEach(itemFile => {

                            this.listDraftingDocuments.push({
                                title: 'Pergolas',
                                id: itemFile.id,
                                fileName: itemFile.fileName,
                                fileType: itemFile.fileType,
                                createdOn: itemFile.dateRegisterFormatted,
                                userName: itemFile.userNameRegister
                            });
                        });
                    }

                    let driveWayListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DRAFTING_DRIVEWAY.value);
                    if (driveWayListFiles != null && driveWayListFiles != undefined) {

                        driveWayListFiles.forEach(itemFile => {

                            this.listDraftingDocuments.push({
                                title: 'Driveway',
                                id: itemFile.id,
                                fileName: itemFile.fileName,
                                fileType: itemFile.fileType,
                                createdOn: itemFile.dateRegisterFormatted,
                                userName: itemFile.userNameRegister
                            });
                        });
                    }
                }
            },

            async getPermittingDocuments() {

                let responsePermitting = await this.$store.dispatch("serviceModule/GetPermittingById", this.serviceProjectResponse.id);

                this.listPermitFiles = [];
                
                this.listPermitDocuments = responsePermitting.result.filter(per => per.apply == 1);

                this.listPermitDocuments.forEach(itemPermitting => {

                    itemPermitting.notes = null;
                    itemPermitting.fileDocumentName = null;
                    itemPermitting.fileNocName = null;
                    itemPermitting.idFile = 0;
                    itemPermitting.idFileNoc = 0;

                    if (itemPermitting.status != null && itemPermitting.status != undefined && itemPermitting.status != 0) {
                        itemPermitting.statusSelected = {
                            id: itemPermitting.status,
                            description: itemPermitting.statusDescription
                        }
                    }
                    
                    this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.PERMITTING.value, idKey: itemPermitting.id }).then (response => {

                        if (response != null && response != undefined) {
                            response.forEach(itemFile => {
                                
                                this.listPermitFiles.push({
                                    typeDocument: itemFile.typeDocument,
                                    id: itemPermitting.id,
                                    idFile: itemFile.id,
                                    fileName: itemFile.fileName,
                                    fileType: itemFile.fileType,
                                })

                                this.$forceUpdate();
                            })
                        }
                    });

                });
            },

            getFile(idPermitting, typeDocument) {
                
                let itemPermit = {
                    idFile: 0,
                    fileName: '',
                    fileType: '',
                }

                let itemFile = this.listPermitFiles.filter(per => per.id == idPermitting && per.typeDocument == typeDocument);


                if (itemFile != null && itemFile != undefined && itemFile.length > 0) {
                    itemPermit.idFile = itemFile[0].idFile;
                    itemPermit.fileName = itemFile[0].fileName;
                    itemPermit.fileType = itemFile[0].fileType;
                }

                return itemPermit;
            },
            

            async clearTechSpecVariables() {
                this.surveyFile = [];

                this.poolStudioDXFFile = [];

                this.poolStudioPDFFile = [];

                this.poolStudioPhotos = [];

                this.architecturalSitePlanFile = [];

                this.dimensionedEquipmentLocationPhotos = [];

                this.fenceTypeSelected = [];

                this.fenceLocationPhotos = [];

                this.depthDeepestPointLocationPhotos = [];

                this.lightLocationsPhotos = [];

                this.soilTestFile = [];

                this.pergolasPhotos = [];

                this.waterFeaturesPhotos = [];

                this.bbqPhotos = [];
            },

            async getTechSpec() {

                await this.clearTechSpecVariables();                

                let responseTechSpec = await this.$store.dispatch("serviceModule/GetTechSpecById", this.serviceProjectResponse.id);
                this.serviceTechSpecRequest = responseTechSpec.result;
                this.serviceTechSpecRequest.surveyTypeSelected = {
                    id: this.serviceTechSpecRequest.surveyType,
                    description: this.serviceTechSpecRequest.surveyTypeDescription
                }
                
                let listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.PROJECT.value, idKey: this.serviceProjectResponse.id });

                //DETAILS
                let itemSessionDetailSurveyType = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.SURVEY.value)[0];               
                if (itemSessionDetailSurveyType != null && itemSessionDetailSurveyType != undefined) {

                    this.surveyFile = {
                        id: itemSessionDetailSurveyType.id,
                        fileName: itemSessionDetailSurveyType.fileName,
                        fileType: itemSessionDetailSurveyType.fileType,
                        createdOn: itemSessionDetailSurveyType.dateRegisterFormatted,
                        userName: itemSessionDetailSurveyType.userNameRegister
                    }
                }

                let itemSessionDetailPoolStudioDXF = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.POOL_STUDIO_DXF.value)[0];
                if (itemSessionDetailPoolStudioDXF != null && itemSessionDetailPoolStudioDXF != undefined) {

                    this.poolStudioDXFFile = {
                        id: itemSessionDetailPoolStudioDXF.id,
                        fileName: itemSessionDetailPoolStudioDXF.fileName,
                        fileType: itemSessionDetailPoolStudioDXF.fileType,
                        createdOn: itemSessionDetailPoolStudioDXF.dateRegisterFormatted,
                        userName: itemSessionDetailPoolStudioDXF.userNameRegister
                    }
                }

                let itemSessionDetailPoolStudioPDF = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.POOL_STUDIO_PDF.value)[0];
                if (itemSessionDetailPoolStudioPDF != null && itemSessionDetailPoolStudioPDF != undefined) {

                    this.poolStudioPDFFile = {
                        id: itemSessionDetailPoolStudioPDF.id,
                        fileName: itemSessionDetailPoolStudioPDF.fileName,
                        fileType: itemSessionDetailPoolStudioPDF.fileType,
                        createdOn: itemSessionDetailPoolStudioPDF.dateRegisterFormatted,
                        userName: itemSessionDetailPoolStudioPDF.userNameRegister
                    }
                }

                let itemSessionDetailArchitecturalSitePlan = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.ARCHITECTURAL_SITE_PLAN.value)[0];
                if (itemSessionDetailArchitecturalSitePlan != null && itemSessionDetailArchitecturalSitePlan != undefined) {

                    this.architecturalSitePlanFile = {
                        id: itemSessionDetailArchitecturalSitePlan.id,
                        fileName: itemSessionDetailArchitecturalSitePlan.fileName,
                        fileType: itemSessionDetailArchitecturalSitePlan.fileType,
                        createdOn: itemSessionDetailArchitecturalSitePlan.dateRegisterFormatted,
                        userName: itemSessionDetailArchitecturalSitePlan.userNameRegister
                    }
                }
                
                //EQUIPMENT LOCATION
                let listItemSessionDimensionedEquipmentLocation = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DIMENSIONED_EQUIPMENT_LOCATION.value);  
                listItemSessionDimensionedEquipmentLocation.forEach(itemPhoto => {

                    this.dimensionedEquipmentLocationPhotos.push({
                        id: itemPhoto.id,
                        fileName: itemPhoto.fileNameShort,
                        fileType: itemPhoto.fileType,
                        createdOn: itemPhoto.dateRegisterFormatted,
                        userName: itemPhoto.userNameRegister,
                        src: `data:image/jpg;base64,${itemPhoto.file}`
                    })

                })

                // POOL BARRIER
                let listIDFenceType = this.serviceTechSpecRequest.fenceType.split(';');
                await listIDFenceType.forEach(itemFence => {
                    if (itemFence) {

                        this.fenceTypeSelected.push({
                            id: parseInt(itemFence),
                            description: this.listFenceType.filter(fen => fen.id == parseInt(itemFence))[0].description
                        })
                    }
                })
                
                //FENCE LOCATION
                let listItemSessionFenceLocation = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.FENCE_LOCATION.value);
                listItemSessionFenceLocation.forEach(itemPhoto => {

                    this.fenceLocationPhotos.push({
                        id: itemPhoto.id,
                        fileName: itemPhoto.fileNameShort,
                        fileType: itemPhoto.fileType,
                        createdOn: itemPhoto.dateRegisterFormatted,
                        userName: itemPhoto.userNameRegister,
                        src: `data:image/jpg;base64,${itemPhoto.file}`
                    })

                })
                
                //DEPH DEEPEST POINT LOCATION
                let listItemSessionDepthDeepestPointLocation = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DEPH_DEEPEST_POINT_LOCATION.value);  
                listItemSessionDepthDeepestPointLocation.forEach(itemPhoto => {

                    this.depthDeepestPointLocationPhotos.push({
                        id: itemPhoto.id,
                        fileName: itemPhoto.fileNameShort,
                        fileType: itemPhoto.fileType,
                        createdOn: itemPhoto.dateRegisterFormatted,
                        userName: itemPhoto.userNameRegister,
                        src: `data:image/jpg;base64,${itemPhoto.file}`
                    })

                })
                
                //LIGHT LOCATIONS
                let listItemSessionLightLocations = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.LIGHT_LOCATIONS.value);
                listItemSessionLightLocations.forEach(itemPhoto => {

                    this.lightLocationsPhotos.push({
                        id: itemPhoto.id,
                        fileName: itemPhoto.fileNameShort,
                        fileType: itemPhoto.fileType,
                        createdOn: itemPhoto.dateRegisterFormatted,
                        userName: itemPhoto.userNameRegister,
                        src: `data:image/jpg;base64,${itemPhoto.file}`
                    })

                })

                //SOIL TEST
                let itemSessionSoilTest = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.SOIL_TEST.value)[0];
                if (itemSessionSoilTest != null && itemSessionSoilTest != undefined) {
                    
                    this.soilTestFile = {
                        id: itemSessionSoilTest.id,
                        fileName: itemSessionSoilTest.fileName,
                        fileType: itemSessionSoilTest.fileType,
                        createdOn: itemSessionSoilTest.dateRegisterFormatted,
                        userName: itemSessionSoilTest.userNameRegister
                    }
                }
                
                //PERGOLAS
                let listItemSessionPergolas = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERGOLAS.value);
                listItemSessionPergolas.forEach(itemPhoto => {

                    this.pergolasPhotos.push({
                        id: itemPhoto.id,
                        fileName: itemPhoto.fileNameShort,
                        fileType: itemPhoto.fileType,
                        createdOn: itemPhoto.dateRegisterFormatted,
                        userName: itemPhoto.userNameRegister,
                        src: `data:image/jpg;base64,${itemPhoto.file}`
                    })

                })
                
                //WATER FEATURES
                let listItemSessionWaterFeatures = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.WATER_FEATURES.value);  
                listItemSessionWaterFeatures.forEach(itemPhoto => {

                    this.waterFeaturesPhotos.push({
                        id: itemPhoto.id,
                        fileName: itemPhoto.fileNameShort,
                        fileType: itemPhoto.fileType,
                        createdOn: itemPhoto.dateRegisterFormatted,
                        userName: itemPhoto.userNameRegister,
                        src: `data:image/jpg;base64,${itemPhoto.file}`
                    })

                })
                
                //BBQ
                let listItemSessionBBQ = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.BBQ.value);  
                listItemSessionBBQ.forEach(itemPhoto => {

                    this.bbqPhotos.push({
                        id: itemPhoto.id,
                        fileName: itemPhoto.fileNameShort,
                        fileType: itemPhoto.fileType,
                        createdOn: itemPhoto.dateRegisterFormatted,
                        userName: itemPhoto.userNameRegister,
                        src: `data:image/jpg;base64,${itemPhoto.file}`
                    })

                })
            },

            async getHistory() {
                this.listHistory = await this.$store.dispatch("serviceModule/ListHistory", this.serviceRequest.id);
            },

            async revalidateGuid() {

                try {
                    let response = await Vue.prototype.$vanKirkApi.get(`service/validate-project-guid/${this.guid}`,
                    {
                        headers: {
                            "content-type": "application/json"
                        }
                    });

                    if (response.status !== 200) {
                        this.showToast("error", "Warning!", "Invalid token!");
                        this.$router.push({ path: "/login" });
                    }
                    else {
                        this.getRegister(response.data.result);
                    }
                }
                catch {
                    this.showToast("error", "Warning!", "Invalid token!");
                    this.$router.push({ path: "/login" });
                }
            },
        },

        mounted() {
            this.revalidateGuid();
            if (this.guid == undefined || this.guid == null || this.guid == "") {
                this.$router.push({ path: "/login" });
            }
        }
    })
</script>


<style scoped>

    .header {
        font-weight: bold;
        width: 25%;
    }

    .content {
        width: 25%;
    }

    .grandTotal {
        color: var(--color__main) !important;
        font-weight: bold;
        font-size: 30px;
    }

    td {
        padding: 3px;
    }    

    .imageFileName {
        font-size: 10px; 
        margin-left: 5px;
    }

    .cardDownload {
        text-align: center;
    }

    .v-chip {
        font-size: 12px;
        width: 130px !important;
        height: 35px !important;
    }

</style>